/* eslint-disable no-nested-ternary */
import React, {
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { formatPercentCss } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import {
    buildClassNames,
    mergeClassNames,
} from '@ui/utils/classNameUtils';
import SimpleTextInput from '../SimpleTextInput/SimpleTextInput';

import styles from './range.module.scss';

// TODO: Implement debounce and actual values changes validation to prevent abusing onChange invoking

interface DragState {
    knobIndex: number;
}

interface EditState {
    editing: boolean;

    rawValue?: string;
    item?: RangeItem;
}

export type RangeSize = 'normal'|'large';

export type RangeType = 'default'|'danger';

export interface RangeItem {
    title: string;
    value: any;
}

export interface NumberValuesRange {
    min: number;
    max: number;
    step: number;
    allowNull?: boolean;
    nullAsMax?: boolean;
    labelFormatter: (value: any) => string;
}

export type ValuesRange = NumberValuesRange | RawItemsRange | ItemsRange | string[];

export interface IEditorConfig {
    minStep?: number;
    postfix?: string;
    parse?: (rawValue: any) => any;
    format?: (rawValue: any) => any;
}

export type EditorHandler = (rawValue: any, config: IEditorConfig) => Promise<RangeItem>;

export interface RangeProps extends IConnectableInputProps {
    title?: JSX.Element | React.ReactNode;
    valueTitle?: string;
    labelRender?: (stepsToRender: RangeItem[]) => JSX.Element | React.ReactNode;
    labelFooter?: JSX.Element | React.ReactNode;

    range: ValuesRange;
    size?: RangeSize;
    type?: RangeType;
    nonDisabledLabel?: boolean;
    useZeroAsEdges?: boolean;
    withEdgeLabels?: boolean;

    disabled?: boolean;
    editable?: boolean;

    editorConfig?: IEditorConfig;
    onEditComplete?: EditorHandler;

    bottomWarningText?: string;
    lineClassname?: string;
    containerClassName?: string;
    contentClassName?: string;
    lineClassName?: string;
    titleClassName?: string;
    labelClassName?: string;
    /** Дополнительный стиль для значений */
    valueClassName?: string;
    white?: boolean;
    withoutBorder?: boolean;
    revert?: boolean;
    noEditButtonSpacing?: boolean;
}

const buildStepsForNumberValuesRange = (config: NumberValuesRange): RangeItem[] => {
    if (config.step <= 0.0) {
        return [];
    }

    const items: RangeItem[] = [];
    if (config.allowNull && !config.nullAsMax) {
        items.push({
            title: config.labelFormatter
                ? config.labelFormatter(null)
                : '-',
            value: null,
        });
    }

    let value = config.min;
    while (value <= config.max) {
        items.push({
            title: config.labelFormatter
                ? config.labelFormatter(value)
                : value.toString(),
            value,
        });

        value = Number.parseFloat((value + config.step).toFixed(4));
    }

    if (config.allowNull && config.nullAsMax) {
        items.push({
            title: config.labelFormatter
                ? config.labelFormatter(null)
                : '-',
            value: null,
        });
    }

    return items;
};

export interface ItemsRange {
    values: any[];
    labelFormatter: (value: any) => string;
}

export interface RawItemsRange {
    items: RangeItem[];
}

const buildStepsFromValues = (config: ItemsRange): RangeItem[] => config.values.map(value => ({
    title: config.labelFormatter
        ? config.labelFormatter(value)
        : value.toString(),
    value,
}));

const buildStepsFromItems = (config: any): RangeItem[] => config.items.map(item => ({
    title: item.title,
    value: item.value,
}));

const buildStepsFromArray = (config: any[]): RangeItem[] => config.map(v => ({
    title: v.toString(),
    value: v as any,
}));

const buildSteps = (config: any): RangeItem[] => {
    if (Array.isArray(config)) {
        return buildStepsFromArray(config);
    }

    if (typeof config.min === 'number' && typeof config.max === 'number') {
        return buildStepsForNumberValuesRange(config);
    }

    if (config.values) {
        return buildStepsFromValues(config);
    }

    if (config.items) {
        return buildStepsFromItems(config);
    }

    return [];
};

const isListValues = (config: any): boolean => {
    if (!config) {
        return false;
    }

    if (Array.isArray(config)) {
        return true;
    }

    if (config.values || config.items) {
        return true;
    }

    return false;
};

const defaultLabelRender = (stepsToRender: RangeItem[]): JSX.Element|React.ReactNode => stepsToRender
    .map(step => step && step.title)
    .join(' – ');

interface EditableRangeInputProps {
    size: RangeSize;
    value: string;
    editorConfig?: IEditorConfig;
    max?: number;
    onChange: (v: any) => void;
    onBlur: (v: any) => void;
    onCancel?: () => void;
}

const EditableRangeInput: React.FC<EditableRangeInputProps> = props => {
    const formatter = props.editorConfig?.format;
    const parser = props.editorConfig?.parse;

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;
        const value = typeof parser === 'function'
            ? parser(rawValue)
            : rawValue;

        if (props.max && value > props.max) {
            return props.onChange(props.max);
        }

        props.onChange(value);
    }, [ parser, props.onChange ]);

    const onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;
        const value = typeof parser === 'function'
            ? parser(rawValue)
            : rawValue;

        props.onBlur(value);
    }, [ parser, props.onBlur ]);

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const key = e.key.toUpperCase();

            const rawValue = e.currentTarget.value;
            const value = typeof parser === 'function'
                ? parser(rawValue)
                : rawValue;

            if (key === 'ENTER' || key === 'NUM_ENTER') {
                props.onBlur(value);
                return;
            }

            if (key === 'ESCAPE') {
                props.onCancel && props.onCancel();

            }
        },
        [ parser, props.onBlur, props.onCancel ]
    );

    const inputRef = useRef<HTMLInputElement>();

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.select();
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className={styles['editor__input-wrapper']}>
            <SimpleTextInput
                ref={inputRef}
                size={props.size === 'large' ? 'large' : 'default'}
                value={typeof formatter === 'function'
                    ? formatter(props.value)
                    : props.value}
                postfix={props.editorConfig?.postfix}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
            />
        </div>
    );
};

const Range: React.FC<RangeProps> = (props: RangeProps) => {
    const {
        size = 'normal',
        type = 'default',
        revert,
    } = props;

    const isStrictValues = isListValues(props.range);

    const steps = useMemo(
        () => buildSteps(props.range),
        [ props.range ]
    );

    const knobValues: any[] = useMemo(
        () => Array.isArray(props.value)
            ? props.value
            : [ props.value ],
        [ props.value ]
    );

    const containerRef = useRef<HTMLDivElement>();

    // States

    const [dragState, setDragState] = useState<DragState>(undefined);
    const [editState, setEditState] = useState<EditState>({
        editing: false,
        rawValue: undefined,
    });

    const findItemIndex = (value: any) => {
        if (typeof value === 'number') {
            const distanceMap = steps.reduce((acc, item: RangeItem, currentIndex: number) => {
                const d = Math.abs(item.value - value);
                acc.push({
                    distance: d,
                    exactly: d < 0.001,
                    index: currentIndex,
                });

                return acc;
            }, []);

            return distanceMap.sort((a, b) => a.distance - b.distance)[0].index;
        }

        // eslint-disable-next-line eqeqeq
        const index = steps.findIndex(step => step.value == value);
        return index >= 0 ? index : 0;
    };

    const knobIndexes = useMemo(() =>
        knobValues.map((value, idx) => {
            if (props.useZeroAsEdges && value === 0) {
                if (idx === 0) {
                    return 0;
                }

                if (idx === knobValues.length - 1) {
                    return steps.length - 1;
                }
            }

            return findItemIndex(value);
        })
    , [ knobValues, steps ]);

    // Callbacks

    const didKnobButtonClick = (e: React.MouseEvent<HTMLDivElement>, knobIndex?: number): void => {
        if (e.buttons !== 1 && e.type !== 'touchstart') {
            return;
        }

        setDragState({
            knobIndex,
        });
    };

    const didMouseMove = (e: MouseEvent): void => {
        if (!dragState) {
            return;
        }

        if (e.buttons !== 1 && e.type !== 'touchmove') {
            return;
        }

        e.preventDefault();

        const clientX = e.type.startsWith('touch')
            ? (e as any).touches[0].clientX
            : e.clientX;

        const rect = containerRef.current.getBoundingClientRect();
        const delta = clientX - rect.x - 8;

        // Clamp
        const offset = Math.min(rect.width, Math.max(delta, 0));

        const step = rect.width / (steps.length - 1);
        let stepIndex = Math.max(0, Math.min(Math.round(offset / step), steps.length - 1));

        // Restrictions
        if (Array.isArray(props.value)) {
            if (dragState.knobIndex < knobIndexes.length - 1) {
                const nextKnobStepIndex = knobIndexes[dragState.knobIndex + 1];
                stepIndex = Math.min(stepIndex, nextKnobStepIndex - 1);
            }

            if (dragState.knobIndex > 0) {
                const prevKnobStepIndex = knobIndexes[dragState.knobIndex - 1];
                stepIndex = Math.max(stepIndex, prevKnobStepIndex + 1);
            }
        }

        const isEdgeStep = stepIndex === 0 || stepIndex === steps.length - 1;
        const value = props.useZeroAsEdges && isEdgeStep && Array.isArray(props.value)
            ? 0
            : steps[stepIndex].value;

        setEditState({
            editing: false,
            item: undefined,
            rawValue: undefined,
        });

        if (Array.isArray(props.value)) {
            if (props.value[dragState.knobIndex] !== value) {
                const newValues = [...props.value];
                newValues[dragState.knobIndex] = value;

                props.onChange && props.onChange(newValues);
            }
        } else if (props.value !== value) {
            props.onChange && props.onChange(value);
        }
    };

    const didMouseUp = (e: MouseEvent): void => {
        if (!dragState) {
            return;
        }

        setDragState(undefined);
    };

    React.useEffect(() => {
        if (props.disabled) {
            return;
        }

        document.addEventListener('mousemove', didMouseMove);
        document.addEventListener('touchmove', didMouseMove as any);
        document.addEventListener('mouseup', didMouseUp);
        document.addEventListener('touchend', didMouseUp as any);

        return () => {
            document.removeEventListener('mousemove', didMouseMove);
            document.removeEventListener('touchmove', didMouseMove as any);
            document.removeEventListener('mouseup', didMouseUp);
            document.removeEventListener('touchend', didMouseUp as any);
        };
    });

    const getKnobPosition = itemIndex => itemIndex >= 0
        ? (
            itemIndex < steps.length
                ? itemIndex / (steps.length - 1)
                : 1.0
        )
        : 0.0;

    const getRangeStartPosition = knobIndex => getKnobPosition(knobIndexes[knobIndex - 1]);

    const getRangeWidth = knobIndex => getKnobPosition(knobIndexes[knobIndex]) - getKnobPosition(knobIndexes[knobIndex - 1]);

    const renderLabel = props.labelRender || defaultLabelRender;

    const integratedLabel = renderLabel(editState && editState.item && [ editState.item ] ||
        (isStrictValues
            ? knobIndexes.map(index => steps[index])
            : knobValues.map<RangeItem>(value => ({
                value,
                title: typeof (props.range as NumberValuesRange).labelFormatter === 'function'
                    ? (props.range as NumberValuesRange).labelFormatter(value)
                    : value?.toString(),
            }))
        )
    );

    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            props.disabled && 'container--disabled',
            `container--size-${size}`,
            `container--type-${type}`,
        ]),
        props.containerClassName,
    ]);

    const labelClassName = mergeClassNames([
        buildClassNames(styles, [
            'label',
            props.nonDisabledLabel && 'label--non-disabled',
        ]),
        props.labelClassName,
    ]);

    const getEditableRangeInputValue = () =>  {
        if (editState?.rawValue === undefined) {
            return knobValues[0];
        }

        return editState?.rawValue;
    };

    const contentClassName = mergeClassNames([
        buildClassNames(styles, [
            'content',
            props.white && 'content--white',
            props.withoutBorder && 'content--without-border',
            (typeof props.title === 'undefined' || props.noEditButtonSpacing) ? 'content--without-spacing' : '',
        ]),
        props.contentClassName,
    ]);

    const rangeClassName = mergeClassNames([
        buildClassNames(styles, [
            'range',
            props.withoutBorder && 'range--without-border',
        ]),
        props.lineClassName,
    ]);

    const knobsContainerClassName = buildClassNames(styles, [
        'knobs-container',
        props.withoutBorder && 'knobs-container--without-border',
    ]);

    const valueTitleClassName = mergeClassNames([
        buildClassNames(styles, [
            'label',
            'value-title',
            props.editable && props.title && 'value-title--editable',
        ]),
        props.valueClassName,
    ]);

    const titleClassName = mergeClassNames([
        styles['title'],
        props.titleClassName,
    ]);

    const getLeftPosition = (knobIndex: number) => revert ? getRangeWidth(knobIndex) : getRangeStartPosition(knobIndex);
    const getWidth = (knobIndex: number) => revert ? 1 - getRangeWidth(knobIndex) : getRangeWidth(knobIndex);

    return (
        <div className={containerClassName}>
            <div className={contentClassName}>
                {props.title && (
                    <div className={titleClassName}>
                        {props.title}
                    </div>
                )}

                {props.editable
                    ? (editState.editing
                        ? (
                            <div className={styles['value-title-container']}>
                                <EditableRangeInput
                                    size={props.size}
                                    editorConfig={props.editorConfig}
                                    value={getEditableRangeInputValue()}
                                    max={typeof (props.range as NumberValuesRange).max === 'number' ? (props.range as NumberValuesRange).max : undefined}
                                    onChange={v => setEditState({
                                        ...editState,
                                        rawValue: v,
                                    })}
                                    onCancel={() => {
                                        setEditState({
                                            editing: false,
                                            rawValue: undefined,
                                            item: undefined,
                                        });
                                    }}
                                    onBlur={(v: any) => {
                                        const {
                                            onEditComplete,
                                            onChange,
                                        } = props;
                                        if (onEditComplete) {
                                            onEditComplete(v, props.editorConfig)
                                                .then(item => {
                                                    setEditState({
                                                        editing: false,
                                                        rawValue: item.value,
                                                        item,
                                                    });

                                                    onChange && onChange(item.value);
                                                })
                                                .catch(() => {
                                                    setEditState({
                                                        editing: true,
                                                        rawValue: v,
                                                        item: undefined,
                                                    });
                                                });
                                        } else {
                                            props.onChange && props.onChange(v);
                                            setEditState({
                                                editing: false,
                                                rawValue: v,
                                                item: undefined,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        )
                        : (
                            <div className={styles['value-title-container']}>
                                {props.valueTitle && (
                                    <div className={valueTitleClassName}>
                                        {props.valueTitle}
                                    </div>
                                )}
                                <button
                                    className={styles['editor__button']}
                                    onClick={() => setEditState({
                                        editing: true,
                                        item: undefined,
                                        rawValue: undefined,
                                    })}
                                >
                                    <div className={labelClassName}>
                                        {integratedLabel}
                                    </div>
                                </button>
                            </div>
                        ))
                    : (
                        <div className={styles['value-title-container']}>
                            {props.valueTitle && (
                                <div className={valueTitleClassName}>
                                    {props.valueTitle}
                                </div>
                            )}
                            <div className={labelClassName}>
                                {integratedLabel}
                            </div>
                        </div>
                    )
                }
                {props.labelFooter && (
                    <div>
                        {props.labelFooter}
                    </div>
                )}
            </div>

            <div className={rangeClassName} ref={containerRef}>
                {knobIndexes.map((stepIndex: any, knobIndex) => (knobIndexes.length === 1 || knobIndexes.length > 1 && knobIndex > 0) && (
                    <React.Fragment key={knobIndex}>
                        <div
                            className={styles['range__area']}
                            style={{
                                left: formatPercentCss(getLeftPosition(knobIndex)),
                                width: formatPercentCss(getWidth(knobIndex)),
                            }}
                        />
                    </React.Fragment>
                ))}
            </div>

            <div className={knobsContainerClassName} ref={containerRef}>
                {knobIndexes.map((stepIndex: any, knobIndex) => (
                    <React.Fragment key={knobIndex}>
                        <div
                            className={buildClassNames(styles, [
                                'knob',
                                !dragState && 'knob--active',
                            ])}
                            style={{ left: formatPercentCss(getKnobPosition(stepIndex)) }}
                            onMouseDown={e => didKnobButtonClick(e, knobIndex)}
                            onTouchStart={e => didKnobButtonClick(e as any, knobIndex)}
                        >
                            <div
                                className={styles['knob__button']}
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>

            {props.withEdgeLabels && steps && steps.length > 1 && (
                <div className={styles['edge-labels-container']}>
                    <div className={buildClassNames(styles, ['edge-label', 'edge-label--left'])}>
                        {steps[0].title}
                    </div>
                    <div className={buildClassNames(styles, ['edge-label', 'edge-label--right'])}>
                        {steps[steps.length - 1].title}
                    </div>
                </div>
            )}

            {props.bottomWarningText &&
                <p className={styles['bottom-warning-text']}>{props.bottomWarningText}</p>
            }
        </div>
    );
};

export const RangeField = connectToField(Range);

export default React.memo(Range);