'use client';

import React, {
    useCallback,
    useState,
} from 'react';
import { cnx } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import StrategySelector from './StrategySelector/StrategySelector';
import ChartSection from './ChartSection/ChartSection';
import InvestmentCalculatorResult from './InvestmentCalculatorResult/InvestmentCalculatorResult';
import InvestmentCalculatorForm from './InvestmentCalculatorForm/InvestmentCalculatorForm';
import InvestmentCalculatorFooter from '@app/components/client/investors/InvestmentCalculator/InvestmentCalculatorFooter/InvestmentCalculatorFooter';
import StrategiesTableModal from './StrategiesTable/StrategiesTableModal';
import InvestmentCalculatorFooterButtons from './InvestmentCalculatorFooterButtons/InvestmentCalculatorFooterButtons';

import styles from './InvestmentCalculator.module.scss';

export interface IProps {
    /**
     * Заголовок блока
     */
    title?: string;
}

/**
* Компонент для отрисовки калькулятора автоинвестирования
*/
const InvestmentCalculator = (props: IProps) => {
    const { title } = props;
    const [isOpenModal, setOpenModal] = useState(false);

    const didModalClosed = useCallback(() => setOpenModal(false), []);
    const didModalOpened = useCallback(() => setOpenModal(true), []);

    return (
        <>
            <div className={cnx([ 'r-mobile', styles['form-title'] ])}>
                {title ?? 'Калькулятор'}
            </div>
            <div className={styles['container']}>
                <SectionPart withShadow className={styles['container__calculator']}>
                    <h2 className={cnx([ 'r-not-mobile', styles['form-title'] ])}>
                        {title ?? 'Калькулятор'}
                    </h2>
                    <InvestmentCalculatorForm />

                    <StrategySelector />

                    <InvestmentCalculatorResult />

                    <p className={styles['info']}>Вероятность вхождения в диапазон ожидаемой доходности составляет 95%</p>
                    <hr className="h-mb-20" />

                    <InvestmentCalculatorFooterButtons onModalOpen={didModalOpened} />

                </SectionPart>

                <SectionPart withShadow className={styles['container__chart']}>
                    <ChartSection />
                </SectionPart>
            </div>

            <InvestmentCalculatorFooter />
            <StrategiesTableModal isOpen={isOpenModal} onClose={didModalClosed} />
        </>
    );
};

export default InvestmentCalculator;
